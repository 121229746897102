import React from "react";
import { Grid, IconButton, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CardWrapper from "../atoms/cardWrapper";
import ContainerWrapper from "../atoms/containerWrapper";
import TypographyWrapper from "../atoms/TypographyWrapper";
import { useTheme } from "@emotion/react";
import MobileLogo from "../MobileLogo";
const CompetitorPulseASM = () => {
  const competitorData = {
    topCompetitorProducts: [
      "GoodDay: Available in 70% of key outlets",
      "Oreo: Aggressive discounts in North Zone",
    ],
    regionalPriceComparison: "Almond Biscotti vs. Competitors",
    promotions: ["Competitor Product Discounts in East Zone"],
  };
  const theme = useTheme();

  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {isMediumDown && <MobileLogo />}
      <ContainerWrapper>

        <Grid container spacing={3}>
          {/* Competitor Pulse Header */}
          <Grid item xs={12}>
            <CardWrapper>
              <TypographyWrapper variant="h6">
                Competitor Pulse
              </TypographyWrapper>
              <TypographyWrapper variant="body2" fontWeight="normal">
                Track competitors and unlock strategies to boost your sales{" "}
              </TypographyWrapper>
            </CardWrapper>
          </Grid>

          {/* Top Competitor Products */}
          <Grid item xs={12}>
            <CardWrapper>
              <TypographyWrapper variant="subtitle1">
                Top Competitor Products
              </TypographyWrapper>
              {competitorData.topCompetitorProducts.map((product, index) => (
                <TypographyWrapper
                  key={index}
                  variant="body1"
                  fontWeight="normal"
                >
                  {product}
                </TypographyWrapper>
              ))}
            </CardWrapper>
          </Grid>

          {/* Regional Price Comparison */}
          <Grid item xs={12}>
            <CardWrapper>
              <TypographyWrapper variant="subtitle1">
                Regional Price Comparison
              </TypographyWrapper>
              <TypographyWrapper variant="body1" fontWeight="normal">
                {competitorData.regionalPriceComparison}
              </TypographyWrapper>
            </CardWrapper>
          </Grid>

          {/* Promotions Detected */}
          <Grid item xs={12}>
            <CardWrapper>
              <TypographyWrapper variant="subtitle1">
                Promotions Detected This Month
              </TypographyWrapper>
              {competitorData.promotions.map((promotion, index) => (
                <TypographyWrapper
                  key={index}
                  variant="body1"
                  fontWeight="normal"
                >
                  {promotion}
                </TypographyWrapper>
              ))}
            </CardWrapper>
          </Grid>

          {/* Estimated Impact Pie Chart */}
        </Grid>
      </ContainerWrapper>
    </>
  );
};

export default CompetitorPulseASM;
