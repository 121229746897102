import { useMediaQuery } from "@mui/material";
import UnibicLogo from "../assets/unibic_logo.png";
import { useNavigate } from "react-router-dom";

const MobileLogo = () => {
  const isRotated = useMediaQuery("(orientation: landscape)");
  const navigate = useNavigate();
  const userDetails = JSON.parse(sessionStorage.getItem("userDetails") || "{}");
  const role = userDetails?.role;

  return (
    <div
      style={{
        display: "flex",
        width: "98%",
        justifyContent: "space-between",
        position: "fixed",
        top: 0,
        left: 0,
        padding: isRotated ? "2% 2% 3%" : "3% 3% 7%",
        backgroundColor: "#f0f2f8",
      }}
    >
      <img
        onClick={() => {
          navigate(role === "reporting_manager" ? "/asm-dashboard" : "/screens");
        }}
        fetchpriority="high"
        width={"100"}
        height={"44"}
        src={UnibicLogo}
        className="attachment-full size-full wp-image-2014"
        alt=""
        sizes="(max-width: 500px) 100vw, 500px"
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontSize: "16px",
            fontWeight: "500",
            fontFamily: "Roboto",
            color: "#555555",
          }}
        >
          AI Agent
        </div>
        <div
          style={{
            fontSize: "16px",
            fontWeight: "400",
            fontFamily: "Roboto",
            color: "#555555",
          }}
        >
          by channelco.ai
        </div>
      </div>
    </div>
  );
};

export default MobileLogo;
