import React from "react";
import { Box, Typography, Chip, useMediaQuery } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { useTheme } from "@emotion/react";
import "swiper/css";
import "swiper/css/pagination";
import { useNavigate } from "react-router-dom";

const CategorySidebar = ({ categories, handleItemClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  return (
    <>
      {isMobile ? (
        <Box sx={{ mt: "7px", width: "100%", pb: "2px" }}>
          <Swiper
            modules={[Pagination]}
            pagination={{ clickable: true }}
            spaceBetween={16}
            slidesPerView={1}
          >
            {categories?.map((category) => (
              <SwiperSlide key={category.title}>
                <Box sx={{ textAlign: "center", pb: "32px" }}>
                  <Typography
                    sx={{
                      mb: 1,
                      fontSize: "14px",
                      fontWeight: "bold",
                      lineHeight: "16px",
                    }}
                  >
                    {category.title}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      justifyContent: "center",
                    }}
                  >
                    {category.items.map((item) => (
                      <Chip
                        key={item.path}
                        label={item.mobileLabel}
                        onClick={() => handleItemClick(item)}
                        sx={{
                          fontSize: "12px",
                          height: "32px",
                          backgroundColor: "#e0e0e0",
                          borderRadius: "10px",
                          cursor: "pointer",
                          "&:hover": { backgroundColor: "#cfcfcf" },
                          "& .MuiChip-label": {
                            paddingLeft: "8px",
                            paddingRight: "8px",
                          },
                        }}
                      />
                    ))}
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      ) : (
        <Box
          sx={{
            position: "fixed",
            left: 0,
            top: "80px",
            bottom: 0,
            width: "12%",
            padding: "16px 16px 0",
            background: "inherit",
            borderRight: "1px solid #ccc",
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
          }}
        >
          {categories.map((category) => (
            <Box key={category.title} sx={{ marginBottom: 1 }}>
              <Typography
                sx={{ marginBottom: 1, fontSize: "16px", fontWeight: "bold" }}
              >
                {category.title}
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                {category.items.map((item) => (
                  <Chip
                    key={item.path}
                    label={item.shortLabel}
                    onClick={() => handleItemClick(item, navigate)}
                    sx={{
                      backgroundColor: "inherit",
                      border: "1px solid hsl(0deg 0% 59% / 50%)",
                      color: "hsl(207, 12%, 43%)",
                      cursor: "pointer",
                      fontSize: "13px",
                      "&:hover": {
                        backgroundColor: "hsl(0, 0%, 100%)",
                        color: "hsl(243, 96%, 67%)",
                      },
                    }}
                  />
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default CategorySidebar;
