import React, { useState } from "react";
import "./login.css";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import UnibicLogo from "../assets/unibic_logo.png";

const Login = ({ onLogin, authError, setAuthError }) => {
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ userId: "", password: "" });
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  const isRotated = useMediaQuery("(orientation: landscape)");

  const navigate = useNavigate();

  const validateUserId = (value) => {
    if (!value) {
      return false;
    }
    return true;
  };

  const handleUserIdChange = (e) => {
    if (authError) {
      setAuthError("");
    }
    const value = e.target.value;
    setUserId(value);
    setErrors({
      ...errors,
      userId: validateUserId(value)
        ? ""
        : "UserId must contain 4 letters followed by 2 digits.",
    });
  };

  const handlePasswordChange = (e) => {
    if (authError) {
      setAuthError("");
    }
    const value = e.target.value;
    setPassword(value);
    setErrors({
      ...errors,
      password:
        value.length < 6 ? "Password must be at least 6 characters." : "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!errors.userId && !errors.password) {
      onLogin(userId, password, navigate);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        marginTop: isMediumDown ? "0" : "4.7%",
        flexDirection: isMediumDown ? "column" : "row",
      }}
    >
      {isMediumDown && (
        <div style={{ padding: isRotated ? "1%" : "3%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <img
              fetchpriority="high"
              width="100"
              height="44"
              src={UnibicLogo}
              className="attachment-full size-full wp-image-2014"
              alt=""
              sizes="(max-width: 500px) 100vw, 500px"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  fontFamily: "Roboto",
                  color: "#555555",
                }}
              >
                AI Agent
              </div>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  fontFamily: "Roboto",
                  color: "#555555",
                }}
              >
                by channelco.ai
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        style={{
          backgroundColor: "inherit",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          height: "100%",
          width:"100%"
        }}
      >
        <form onSubmit={handleSubmit} className="form">
          <div className="form-group">
            <label className="label">UserId</label>
            <input
              type="text"
              value={userId}
              onChange={handleUserIdChange}
              placeholder="Enter UserId"
              className="input"
            />
            {errors.userId && <span className="error">{errors.userId}</span>}
          </div>
          <div className="form-group">
            <label className="label">Password</label>
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Enter Password"
              className="input"
            />
            {errors.password && (
              <span className="error">{errors.password}</span>
            )}
          </div>
          <button type="submit" className="button">
            Login
          </button>
          {authError.length !== 0 && <span className="error">{authError}</span>}
        </form>
      </div>
    </div>
  );
};

export default Login;
