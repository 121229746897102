import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Chat from "./components/Chat";
import Screens from "./components/Screens";
import UnibicNav from "./components/navbar/unibicNavbar";
import ItemPage from "./components/ItemPage";
import Home from "./components/Home";
import Home1 from "./components/Home1";
import SalesOverviewPage from "./components/screen/todaySales";
import TopOutletOpportunities from "./components/screen/topOutlets";
import NewOutletRadar from "./components/screen/outletRadar";
import ProductPushPriority from "./components/screen/productPushPriority";
import SalesObstacleMap from "./components/screen/salesObstacleMap";
import InstantWins from "./components/screen/instantWins";
import NextBestAction from "./components/screen/nextBestAction";
import CompetitorPulse from "./components/screen/competitorPulse";
import CustomerFeedbackScorecard from "./components/screen/customerFeedbackScoreCard";
import Login from "./pages/login";
import HeroOfTheDayChallenge from "./components/screen/DayHero";
import ScreenSections_ASM from "./components/asm_screen";
import SalesObstacleMapASM from "./components/asm_screen/salesObstacleMapASM";
import TodaySalesASM from "./components/asm_screen/todaySales_ASM";
import TopOutletOpportunitiesASM from "./components/asm_screen/topOutlets";
import CustomerFeedbackScorecardASM from "./components/asm_screen/customerFeedbackASM";
import CompetitorPulseASM from "./components/asm_screen/competitorPulseASM";
import TerritoryASM from "./components/asm_screen/territoryASM";
import TeamPerformance from "./components/asm_screen/teamPerformanceASM";
import NewTerritoryASM from "./components/asm_screen/territoryASM";
import InstantWinsASM from "./components/asm_screen/instantWins";
import NextBestActionASM from "./components/asm_screen/nextBestActionASM";
import ProductPushPriorityASM from "./components/asm_screen/productPushPriorityASM";

const Navigator = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isItemLoading, setIsItemLoading] = useState(false);
  const [authError, setAuthError] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!sessionStorage.getItem("access_token")
  );
  const [userLang, setUserLang] = useState("English");

  const [isInputFocused, setIsInputFocused] = useState(false);
  useEffect(() => {
    console.log("focus", isInputFocused);
  }, [isInputFocused]);

  const categories = [
    {
      title: "Top Selling Outlets",
      items: [
        {
          label: "Top selling outlets by category size",
          shortLabel: "By Category",
          mobileLabel: "Category",
          path: "top-selling-by-category-size",
        },
        {
          label: "Top selling outlets by pack family",
          mobileLabel: "Pack",
          shortLabel: "By Pack ",
          path: "top-selling-by-pack-family",
        },
        {
          label: "Top selling outlets by brand family",
          mobileLabel: "Brand",
          shortLabel: "By Brand ",
          path: "top-selling-by-brand-family",
        },
        {
          label: "Top 10 selling outlets",
          mobileLabel: "Top 10",
          shortLabel: "Top 10 ",
          path: "top-10-selling-outlets",
        },
        {
          label: "Top 50 selling outlets",
          mobileLabel: "Top 50",
          shortLabel: "Top 50 ",
          path: "top-50-selling-outlets",
        },
      ],
    },
    {
      title: "Non-Billed Outlets",
      items: [
        {
          label: "Non billed outlets in the last 15 days",
          mobileLabel: "15 Days",
          shortLabel: "Last 15 Days",
          path: "non-billed-15-days",
        },
        {
          label: "Non billed outlets in the last 30 days",
          mobileLabel: "30 Days",
          shortLabel: "Last 30 Days",
          path: "non-billed-30-days",
        },
        {
          label: "Non billed outlets in the last 45 days",
          mobileLabel: "45 Days",
          shortLabel: "Last 45 Days",
          path: "non-billed-45-days",
        },
        {
          label: "Non billed outlets in the last 60 days",
          mobileLabel: "60 Days",
          shortLabel: "Last 60 Days",
          path: "non-billed-60-days",
        },
      ],
    },
    {
      title: "Other Metrics",
      items: [
        {
          label: "Outlets with frequent sales",
          shortLabel: "Frequent Sales",
          mobileLabel: "Frequent",
          path: "frequent-sales-top-outlets",
        },
        {
          label: "Outlets with maximum sales",
          shortLabel: "Max Sales",
          mobileLabel: "Max Sales",
          path: "max-sales-outlets",
        },
        {
          label: "Outlets with top contributions",
          shortLabel: "Top Contributor ",
          mobileLabel: "Top Contributor",
          path: "top-contributor-outlets",
        },
      ],
    },
  ];
  const handleItemClick = async (item, navigate) => {
    const access_token = sessionStorage.getItem("access_token");
    const salesDataApi = `${process.env.REACT_APP_SALES_DATA_API}/${item.path}`;
    setIsItemLoading(true);
    const response = await fetch(salesDataApi, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    });
    if (!response.ok) {
      console.error("Failed to fetch data:", response.statusText);
      return;
    }

    setIsItemLoading(false);
    const data = await response.json();

    navigate(`/item/${item.path}`, {
      state: {
        question: item.label,
        data: data,
        categories: categories,
      },
    });
  };

  const handleLogin = async (username, password, navigate) => {
    const loginApi = process.env.REACT_APP_LOGIN_API;
    const response = await fetch(loginApi, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    });
    const loginData = await response.json();
    if (!response.ok) {
      setAuthError(loginData.error);
    } else {
      const userDetails = {
        username: loginData?.username,
        name: loginData?.name,
        role: loginData?.role,
      };

      setIsAuthenticated(true);
      sessionStorage.setItem("access_token", loginData?.token);
      sessionStorage.setItem("userDetails", JSON.stringify(userDetails));

      navigate("/home");
    }
  };

  const handleLogout = async (username) => {
    const logoutApi = process.env.REACT_APP_LOGOUT_API;
    const access_token = sessionStorage.getItem("access_token");
    const response = await fetch(logoutApi, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      body: JSON.stringify({ username }),
    });

    const logoutData = await response.json();
    setIsAuthenticated(false);
    sessionStorage.clear();
  };

  const PrivateRoute = ({ children }) => {
    return isAuthenticated ? children : <Navigate to="/login" />;
  };

  return (
    <Router>
      <UnibicNav
        isInputFocused={isInputFocused}
        isAuthenticated={isAuthenticated}
        handleLogout={handleLogout}
      />
      <Routes>
        <Route
          path="/login"
          element={
            <Login
              onLogin={handleLogin}
              authError={authError}
              setAuthError={setAuthError}
            />
          }
        />
        <Route
          path="/home"
          element={
            <PrivateRoute>
              <Home
                categories={categories}
                handleItemClick={handleItemClick}
                isLoading={isLoading}
                userLang={userLang}
                setUserLang={setUserLang}
                setIsInputFocused={setIsInputFocused}
                isItemLoading={isItemLoading}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/home1"
          element={
            <PrivateRoute>
              <Home1
                categories={categories}
                handleItemClick={handleItemClick}
                isLoading={isLoading}
                userLang={userLang}
                setUserLang={setUserLang}
                setIsInputFocused={setIsInputFocused}
                isItemLoading={isItemLoading}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/chat"
          element={
            isAuthenticated ? (
              <Chat
                categories={categories}
                handleItemClick={handleItemClick}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                userLang={userLang}
                setUserLang={setUserLang}
                setIsInputFocused={setIsInputFocused}
              />
            ) : (
              <Login onLogin={handleLogin} />
            )
          }
        />
        <Route
          path="/screens"
          element={
            <PrivateRoute>
              <Screens />
            </PrivateRoute>
          }
        />
        <Route
          path="/item/:path"
          element={
            <PrivateRoute>
              <ItemPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/"
          element={<Navigate to={isAuthenticated ? "/home" : "/login"} />}
        />
        <Route
          path="/today-sales"
          element={
            <PrivateRoute>
              <SalesOverviewPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/top-outlets"
          element={
            <PrivateRoute>
              <TopOutletOpportunities />
            </PrivateRoute>
          }
        />
        <Route
          path="/outlet-radar"
          element={
            <PrivateRoute>
              <NewOutletRadar />
            </PrivateRoute>
          }
        />
        <Route
          path="/hero-of-the-day"
          element={
            <PrivateRoute>
              <HeroOfTheDayChallenge />
            </PrivateRoute>
          }
        />
        <Route
          path="/product-push-priority"
          element={
            <PrivateRoute>
              <ProductPushPriority />
            </PrivateRoute>
          }
        />
        <Route
          path="/sales-obstacle-map"
          element={
            <PrivateRoute>
              <SalesObstacleMap />
            </PrivateRoute>
          }
        />
        <Route
          path="/instant-wins"
          element={
            <PrivateRoute>
              <InstantWins />
            </PrivateRoute>
          }
        />
        <Route
          path="/next-best-action"
          element={
            <PrivateRoute>
              <NextBestAction />
            </PrivateRoute>
          }
        />
        <Route
          path="/competitor-pulse"
          element={
            <PrivateRoute>
              <CompetitorPulse />
            </PrivateRoute>
          }
        />
        <Route
          path="/feedback-scorecard"
          element={
            <PrivateRoute>
              <CustomerFeedbackScorecard />
            </PrivateRoute>
          }
        />

        <Route
          path="/asm-dashboard"
          element={
            <PrivateRoute>
              <ScreenSections_ASM />
            </PrivateRoute>
          }
        />
        <Route
          path="/asm-today-sales"
          element={
            <PrivateRoute>
              <TodaySalesASM />
            </PrivateRoute>
          }
        />
        <Route
          path="/asm-sales-obstacle-map"
          element={
            <PrivateRoute>
              <SalesObstacleMapASM />
            </PrivateRoute>
          }
        />
        <Route
          path="/asm-top-outlets"
          element={
            <PrivateRoute>
              <TopOutletOpportunitiesASM />
            </PrivateRoute>
          }
        />
        <Route
          path="/asm-feedback-scorecard"
          element={
            <PrivateRoute>
              <CustomerFeedbackScorecardASM />
            </PrivateRoute>
          }
        />
        <Route
          path="/asm-competitor-pulse"
          element={
            <PrivateRoute>
              <CompetitorPulseASM />
            </PrivateRoute>
          }
        />
        <Route
          path="/asm-outlet-radar"
          element={
            <PrivateRoute>
              <NewTerritoryASM />
            </PrivateRoute>
          }
        />
        <Route
          path="/asm-next-best-action"
          element={
            <PrivateRoute>
              <NextBestActionASM />
            </PrivateRoute>
          }
        />
        <Route
          path="/asm-hero-of-the-day"
          element={
            <PrivateRoute>
              <TeamPerformance />
            </PrivateRoute>
          }
        />
        <Route
          path="/asm-instant-wins"
          element={
            <PrivateRoute>
              <InstantWinsASM />
            </PrivateRoute>
          }
        />
        <Route
          path="/asm-product-push-priority"
          element={
            <PrivateRoute>
              <ProductPushPriorityASM />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default Navigator;
