import { Box, Container, Link, Typography, useMediaQuery } from "@mui/material";

import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import IconButton from "@mui/material/IconButton";
import thumbsUpIcon from "../../assets/thumbsUpIcon.svg";
import thumbsUpIconFilled from "../../assets/thumbsUpIconFilled.svg";
import thumbsDownIcon from "../../assets/thumbsDownIcon.svg";
import thumbsDownIconFilled from "../../assets/thumbsDownIconFilled.svg";
import PulseLoader from "react-spinners/PulseLoader";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Pagination,
} from "@mui/material";
const MarkdownImage = ({ src, alt }) => {
  const [imageLoaded, setImageLoaded] = useState(true);
  return imageLoaded ? (
    <img
      src={src}
      alt={alt}
      style={{
        width: "200px",
        height: "240px",
        display: "block",
        margin: "10px 0",
      }}
      onError={() => setImageLoaded(false)}
    />
  ) : null;
};
const MarkdownLink = ({ href, children }) => (
  <Link
    href={href}
    onError={(e) => (e.target.style.display = "none")}
    target="_blank"
    rel="noopener noreferrer"
    color="#7ab7ff"
  >
    {children}
  </Link>
);
const renderers = {
  img: MarkdownImage,
  a: MarkdownLink,
};

function MessageContainer({
  messages,
  sessionId,
  handleSend,
  loading,
  categories,
  handleItemClick,
}) {
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  const [feedback, setFeedback] = useState({});
  const lastMessageRef = useRef(null);
  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const handleFeedback = async (index, feedbackType) => {
    setFeedback((prevFeedback) => {
      const updatedFeedback = {
        ...prevFeedback,
        [index]: feedbackType === prevFeedback[index] ? 0 : feedbackType,
      };
      updateFeedbackInDatabase({
        sessionId,
        messageId: messages[index].id,
        responseFeedback: updatedFeedback[index],
      });
      return updatedFeedback;
    });
  };

  const updateFeedbackInDatabase = async (feedbackData) => {
    try {
      const access_token = sessionStorage.getItem("access_token");
      const response = await fetch(process.env.REACT_APP_UPDATE_FEEDBACK_API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        body: JSON.stringify(feedbackData),
      });

      if (!response.ok) {
        throw new Error("Failed to update feedback");
      }

      const result = await response.json();
      console.log("Feedback updated successfully:", result);
    } catch (error) {
      console.error("Error updating feedback:", error);
    }
  };
  const [page, setPage] = useState(0);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = page * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return (
    <Container
      sx={{
        flexGrow: 1,
        width: isMediumDown ? "100%" : "80%",
        display: "flex",
        flexDirection: "column",
        maxWidth: isMediumDown ? "100%" : "80%",
      }}
    >
      {messages.length !== 0 ? (
        <>
          <div
            style={{
              paddingTop: isMediumDown ? "0" : "90px",
            }}
          >
            {messages.map((message, index) => (
              <Box
                key={index}
                sx={{
                  marginBottom: "25px",
                }}
                ref={index === messages.length - 1 ? lastMessageRef : null}
              >
                <div
                  style={{
                    alignItems: "flex-start",
                    display: "flex",
                    justifyContent:
                      message.sender !== "user" ? "flex-start" : "flex-end",
                    gap: "15px",
                    paddingBottom:
                      message.sender !== "user" && message.sqlData?.length !== 0
                        ? "2%"
                        : "0",
                  }}
                >
                  {message.sender !== "user" && (
                    <Box>
                      <img
                        src={require("../../assets/chatbotlogo.png")}
                        alt="Chatbot Logo"
                        width={30}
                      />
                    </Box>
                  )}
                  {message.sender !== "user" &&
                    message.sqlData.length !== 0 && (
                      <div
                        style={{
                          overflowX:  "auto",
                          marginTop: "1%",
                          scrollbarWidth: "thin",
                          scrollbarColor: "#A8A8A8 #f4f6f8",
                        }}
                      >
                        <Table
                          style={{
                            borderCollapse: "collapse",
                            width: "100%",
                            minWidth: "600px",
                            marginTop: "10px",
                          }}
                        >
                          <TableHead>
                            <TableRow>
                              {message.sqlData.length > 0 &&
                                Object.keys(message.sqlData[0]).map(
                                  (key, index) => (
                                    <TableCell
                                      key={key}
                                      style={{
                                        color: "black",
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                        padding: "10px 8px",
                                        textAlign: "left",
                                        borderBottom: "2px solid #ddd",
                                        position:
                                          index === 0 ? "sticky" : "static",
                                        top: 0,
                                        left: index === 0 ? 0 : "auto",
                                        zIndex: index === 0 ? 1 : "auto",
                                        whiteSpace: "nowrap",
                                        minWidth:
                                          index === 0 ? "150px" : "100px",
                                        maxWidth: "300px",
                                        backgroundColor: "#F8F8FC",
                                      }}
                                    >
                                      {key}
                                    </TableCell>
                                  )
                                )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {message.sqlData
                              .slice(startIndex, endIndex)
                              .map((row, rowIndex) => (
                                <TableRow
                                  key={rowIndex}
                                  style={{
                                    backgroundColor:
                                      rowIndex % 2 === 0 ? "#f2f2f2" : "white",
                                    transition: "background-color 0.2s",
                                    cursor: "pointer",
                                  }}
                                  onMouseEnter={(e) =>
                                    (e.currentTarget.style.backgroundColor =
                                      "#eaf7ff")
                                  }
                                  onMouseLeave={(e) =>
                                    (e.currentTarget.style.backgroundColor =
                                      rowIndex % 2 === 0 ? "#f2f2f2" : "white")
                                  }
                                >
                                  {Object.values(row).map((value, colIndex) => (
                                    <TableCell
                                      key={colIndex}
                                      style={{
                                        color: "#333",
                                        fontSize: "14px",
                                        padding: "10px 8px",
                                        textAlign: "left",
                                        borderBottom: "1px solid #ddd",
                                        position:
                                          colIndex === 0 ? "sticky" : "static",
                                        left: colIndex === 0 ? 0 : "auto",
                                        zIndex: colIndex === 0 ? 1 : "auto",
                                        backgroundColor:
                                          colIndex === 0 ? "white" : "inherit",
                                        whiteSpace: "normal",
                                        minWidth:
                                          colIndex === 0 ? "150px" : "200px",
                                        maxWidth:
                                          colIndex === 0 ? "300px" : "500px",
                                        wordWrap: "break-word",
                                      }}
                                    >
                                      {value}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "20px",
                            padding: "5px",
                            backgroundColor: "#f9f9f9",
                            borderRadius: "8px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          <button
                            onClick={() =>
                              setPage((prev) => Math.max(prev - 1, 0))
                            }
                            disabled={page === 0}
                            style={{
                              padding: "8px",
                              fontSize: "14px",
                              fontWeight: "bold",
                              color: page === 0 ? "#aaa" : "black",
                              backgroundColor: page === 0 ? "#f2f2f2" : "white",
                              border: "1px solid #A8A8A8",
                              borderRadius: "4px",
                              cursor: page === 0 ? "not-allowed" : "pointer",
                              transition: "all 0.3s",
                            }}
                          >
                            Previous
                          </button>
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "bold",
                              color: "#333",
                            }}
                          >
                            Page {page + 1} of{" "}
                            {Math.ceil(message.sqlData.length / itemsPerPage)}
                          </span>
                          <button
                            onClick={() =>
                              setPage((prev) =>
                                Math.min(
                                  prev + 1,
                                  Math.floor(
                                    message.sqlData.length / itemsPerPage
                                  ) -
                                    (message.sqlData.length % itemsPerPage === 0
                                      ? 1
                                      : 0)
                                )
                              )
                            }
                            disabled={
                              (page + 1) * itemsPerPage >=
                                message.sqlData.length &&
                              message.sqlData.length % itemsPerPage === 0
                            }
                            style={{
                              padding: "8px",
                              fontSize: "14px",
                              fontWeight: "bold",
                              color:
                                (page + 1) * itemsPerPage >=
                                  message.sqlData.length &&
                                message.sqlData.length % itemsPerPage === 0
                                  ? "#aaa"
                                  : "black",
                              backgroundColor:
                                (page + 1) * itemsPerPage >=
                                  message.sqlData.length &&
                                message.sqlData.length % itemsPerPage === 0
                                  ? "#f2f2f2"
                                  : "white",
                              border: "1px solid #A8A8A8",
                              borderRadius: "4px",
                              cursor:
                                (page + 1) * itemsPerPage >=
                                  message.sqlData.length &&
                                message.sqlData.length % itemsPerPage === 0
                                  ? "not-allowed"
                                  : "pointer",
                              transition: "all 0.3s",
                            }}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    )}

                  <Typography
                    variant="body1"
                    sx={{
                      display: "inline-block",
                      padding: message.sender === "user" ? "6px 11px" : 0,
                      borderRadius: "20px",
                      backgroundColor:
                        message.sender === "user" ? "hsl(0, 0%, 100%)" : "",
                      color: "hsl(207, 12%, 43%)",
                      maxWidth: isMediumDown ? undefined : "80%",
                      lineHeight:
                        message.sender === "user" ? "1.2rem" : "1.5rem",
                      boxSizing: "border-box",
                      margin: 0,
                      fontSize: isMediumDown ? "14px" : "1rem",
                    }}
                  >
                    <ReactMarkdown
                      children={message.text}
                      components={{
                        ...renderers,
                        p: ({ node, ...props }) => (
                          <p {...props} style={{ margin: 0 }} />
                        ),
                        ul: ({ node, ...props }) => (
                          <ul
                            {...props}
                            style={{
                              listStyleType: "none",
                              padding: 0,
                              margin: 0,
                            }}
                          />
                        ),
                      }}
                      rehypePlugins={[rehypeRaw]}
                    />
                  </Typography>
                </div>

                {message.sender !== "user" && (
                  <div
                    key={message.id}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginLeft: "35px",
                      gap: "2%",
                    }}
                  >
                    <div
                      onClick={() => handleFeedback(index, 1)}
                      size="small"
                      style={{ cursor: "pointer" }}
                    >
                      {feedback[index] === 1 ? (
                        <img src={thumbsUpIconFilled} alt="thumbsUpFilled" />
                      ) : (
                        <img src={thumbsUpIcon} alt="thumbsUp" />
                      )}
                    </div>
                    <div
                      onClick={() => handleFeedback(index, -1)}
                      size="small"
                      style={{ cursor: "pointer" }}
                    >
                      {feedback[index] === -1 ? (
                        <img
                          src={thumbsDownIconFilled}
                          alt="thumbsDownFilled"
                        />
                      ) : (
                        <img src={thumbsDownIcon} alt="thumbsDown" />
                      )}
                    </div>
                  </div>
                )}
              </Box>
            ))}
            {loading && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 2,
                }}
              >
                <PulseLoader color="grey" />
              </Box>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </Container>
  );
}

export default MessageContainer;
