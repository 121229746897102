import React from "react";
import { Grid, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../atoms/containerWrapper";
import TypographyWrapper from "../atoms/TypographyWrapper";
import CardWrapper from "../atoms/cardWrapper";
import dataASM from "./areaSalesManagerData_ASM";
import { useTheme } from "@emotion/react";
import MobileLogo from "../MobileLogo";
const ProductPushPriorityASM = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {isMediumDown && <MobileLogo />}
      <ContainerWrapper>
        <Grid container spacing={3}>
          {/* Product Header */}
          <Grid item xs={12}>
            <CardWrapper>
              <TypographyWrapper variant="h6">
                Product Push Priority
              </TypographyWrapper>
              <TypographyWrapper variant="body2" fontWeight="normal">
                Promote the product with high priority to boost sales in the
                region.
              </TypographyWrapper>
            </CardWrapper>
          </Grid>

          {/* Top Product */}
          <Grid item xs={12} md={6}>
            <CardWrapper>
              <TypographyWrapper variant="subtitle1">
                Top Product
              </TypographyWrapper>
              <TypographyWrapper variant="body1" fontWeight="normal">
                {dataASM.productPushOverview.topProduct}
              </TypographyWrapper>
            </CardWrapper>
          </Grid>

          {/* Stock Level */}
          <Grid item xs={12} md={6}>
            <CardWrapper>
              <TypographyWrapper variant="subtitle1">
                Stock Level
              </TypographyWrapper>
              <TypographyWrapper variant="body1" fontWeight="normal">
                {dataASM.productPushOverview.currentStockLevel}
              </TypographyWrapper>
            </CardWrapper>
          </Grid>

          {/* Total Units Sold */}
          <Grid item xs={12} md={6}>
            <CardWrapper>
              <TypographyWrapper variant="subtitle1">
                Total Units Sold
              </TypographyWrapper>
              <TypographyWrapper variant="body1" fontWeight="normal">
                {dataASM.productPushOverview.totalUnitsSold}
              </TypographyWrapper>
            </CardWrapper>
          </Grid>

          {/* Total Revenue Generated */}
          <Grid item xs={12} md={6}>
            <CardWrapper>
              <TypographyWrapper variant="subtitle1">
                Total Revenue Generated
              </TypographyWrapper>
              <TypographyWrapper variant="body1" fontWeight="normal">
                {dataASM.productPushOverview.totalRevenueGenerated}
              </TypographyWrapper>
            </CardWrapper>
          </Grid>

          {/* Priority Products By Zone */}
          <Grid item xs={12}>
            <CardWrapper>
              <TypographyWrapper variant="subtitle1">
                Priority Products by Zone
              </TypographyWrapper>
              <TypographyWrapper variant="body1" fontWeight="normal">
                <strong>North:</strong>{" "}
                {dataASM.productPushOverview.priorityProductsByZone.North.join(", ")}
                <br />
                <strong>East:</strong>{" "}
                {dataASM.productPushOverview.priorityProductsByZone.East.join(", ")}
              </TypographyWrapper>
            </CardWrapper>
          </Grid>
          </Grid>
      </ContainerWrapper>
    </>
  );
};

export default ProductPushPriorityASM;
