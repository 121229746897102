import React from "react";
import { Grid, IconButton,useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CardWrapper from "../atoms/cardWrapper";
import ContainerWrapper from "../atoms/containerWrapper";
import TypographyWrapper from "../atoms/TypographyWrapper";
import { useTheme } from "@emotion/react";
import MobileLogo from "../MobileLogo";
const InstantWins = () => {
  const navigate = useNavigate();

  const instantWins = {
    highValueSales: [
      { outlet: "Sharma Mart, East Zone", value: "₹30,000" },
      { outlet: "Desai Supermarket, North Zone", value: "₹25,000" },
    ],
    mostSoldProduct: "Chocolate Chip Cookies",
    newOutletsAcquired: { count: 20, target: 25 },
  };

  const theme = useTheme();

  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {isMediumDown && <MobileLogo />}
      <ContainerWrapper>
        <Grid container spacing={3}>
          {/* Instant Wins Header */}
          <Grid item xs={12}>
            <CardWrapper>
              <TypographyWrapper variant="h6">Instant Wins</TypographyWrapper>
              <TypographyWrapper variant="body2" fontWeight="normal">
                Highlights of recent sales successes and milestones
              </TypographyWrapper>
            </CardWrapper>
          </Grid>

          {/* High-Value Sales */}
          <Grid item xs={12}>
            <CardWrapper>
              <TypographyWrapper variant="subtitle1">
                High-Value Sales
              </TypographyWrapper>
              {instantWins.highValueSales.map((sale, index) => (
                <TypographyWrapper
                  key={index}
                  variant="body1"
                  fontWeight="normal"
                >
                  {sale.outlet}: {sale.value}
                </TypographyWrapper>
              ))}
            </CardWrapper>
          </Grid>

          {/* Most Sold Product */}
          <Grid item xs={12} md={6}>
            <CardWrapper>
              <TypographyWrapper variant="subtitle1">
                Most Sold Product
              </TypographyWrapper>
              <TypographyWrapper variant="body1" fontWeight="normal">
                {instantWins.mostSoldProduct}
              </TypographyWrapper>
            </CardWrapper>
          </Grid>

          {/* New Outlets Acquired */}
          <Grid item xs={12} md={6}>
            <CardWrapper>
              <TypographyWrapper variant="subtitle1">
                New Outlets Acquired
              </TypographyWrapper>
              <TypographyWrapper variant="body1" fontWeight="normal">
                {instantWins.newOutletsAcquired.count} /{" "}
                {instantWins.newOutletsAcquired.target}
              </TypographyWrapper>
            </CardWrapper>
          </Grid>
        </Grid>
      </ContainerWrapper>
    </>
  );
};

export default InstantWins;
