import React from "react";
import { Grid, IconButton,useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ContainerWrapper from "../atoms/containerWrapper";
import CardWrapper from "../atoms/cardWrapper";
import dataASM from "./areaSalesManagerData_ASM";
import TypographyWrapper from "../atoms/TypographyWrapper";
import { useTheme } from "@emotion/react";
import MobileLogo from "../MobileLogo";
const NextBestActionASM = () => {
  const navigate = useNavigate();
  const { nextBestAction } = dataASM;
  const theme = useTheme();

  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {isMediumDown && <MobileLogo />}
      <ContainerWrapper>

      <Grid container spacing={3}>
        {/* Next Best Action Header */}
        <Grid item xs={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">Next Best Action</TypographyWrapper>
            <TypographyWrapper variant="body2" fontWeight="normal">
              Here’s the suggested action to maximize sales.
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* High Potential Outlet */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              High Potential Outlet
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {nextBestAction.highPotentialOutlets[0].name}
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              Estimated Monthly Sales:{" "}
              {nextBestAction.highPotentialOutlets[0].estimatedMonthlySales}
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              Predicted Sales Value:{" "}
              {nextBestAction.highPotentialOutlets[0].predictedSalesValue}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Last Order Value */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Last Order Value
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {nextBestAction.lastOrderValue}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>
      </Grid>
    </ContainerWrapper>
    </>
  );
};

export default NextBestActionASM;
