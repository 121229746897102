import React from "react";
import { Typography, IconButton, useMediaQuery } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import dashboardData from "./salesFieldExecutiveData";
import CardWrapper from "../atoms/cardWrapper";
import ContainerWrapper from "../atoms/containerWrapper";
import DataTable from "../atoms/tableWrapper";
import TypographyWrapper from "../atoms/TypographyWrapper";
import { useTheme } from "@emotion/react";
import MobileLogo from "../MobileLogo";
const TopOutletOpportunities = () => {
  const columns = [
    { label: "Outlet Name", field: "outletName" },
    { label: "Sales Potential", field: "salesPotential" },
    { label: "Last Purchase", field: "lastPurchase" },
    {
      label: "Top Products to Pitch",
      field: "topProductsToPitch",
      render: (row) =>
        row.topProductsToPitch
          ? row.topProductsToPitch.join(", ")
          : "No Products Available",
    },
    { label: "Estimated Revenue", field: "estimatedRevenue", align: "right" },
  ];

  // Row data
  const rows = dashboardData.topOutletOpportunities;

  const theme = useTheme();

  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <ContainerWrapper>
      {isMediumDown && <MobileLogo />}
      <CardWrapper>
        <TypographyWrapper variant="h6">
          Top Outlet Opportunities
        </TypographyWrapper>
        <Typography variant="body2">
          Highlighting stores with high sales potential and recommended
          products.
        </Typography>

        <DataTable columns={columns} rows={rows} />
      </CardWrapper>
    </ContainerWrapper>
  );
};

export default TopOutletOpportunities;
