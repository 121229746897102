const dataASM = {
  dailySalesPulse: {
    todaysSales: { value: "₹2,500,000", target: "₹3,000,000", achieved: "83%" },
    topProduct: { name: "Butter Cookies", unitsSold: "12,000" },
    totalRevenueWeekly: "₹18,000,000",
    topPerformingTeam: { name: "East Zone", achievement: "130%" },
    topSalesReps: [
      { rank: 1, name: "Arjun", revenue: "₹500,000" },
      { rank: 2, name: "Priya", revenue: "₹450,000" },
    ],
    weeklyTargetCompletion: "75%",
    averageOrderValue: "₹2,750",
    conversionRate: "40%",
  },
  topOpportunities: [
    {
      description: "New Distributors in East Zone",
      potentialRevenue: "₹500,000",
      productsToPush: ["Almond Biscotti", "Premium Cookies Range"],
      targetOutlets: 10,
    },
    {
      description: "Expansion of SKU in Central Zone",
      potentialRevenue: "₹300,000",
      productsToFocus: ["Sugar-Free Cookies", "Digestive Range"],
    },
  ],
  newTerritoryPerformance: {
    newOutletsAdded: { count: 50, target: 60 },
    topArea: "West Zone",
    averageRevenuePerNewOutlet: "₹20,000", 
    competitors: ["GoodDay", "Sunfeast"],
    scheduledVisits: 15,
  },
  teamPerformance: {
    topTeam: "North Zone",
    totalSales: "₹6,000,000",
    challengeStatus: { completion: "85%", topRep: "Arjun (300 Units Sold)" },
    achievementBadges: ["Best Sales Team", "Fastest Growing Territory"],
  },
  productPushOverview: {
    topProduct: "Almond Biscotti",
    totalUnitsSold: 15000,
    currentStockLevel: "Moderate",
    totalRevenueGenerated: "₹1,500,000",
    priorityProductsByZone: {
      North: ["Chocolate Chip Cookies", "Almond Biscotti"],
      East: ["Sugar-Free Range"],
    },
  },
  salesObstacleMap: {
    lowStockOutlets: 10,
    returnIssues: ["Packaging complaints", "Damaged Goods"],
    delayedDeliveries: {
      North: { count: 5, averageDelay: "3 days" },
    },
    competitorPromotions: [
      { competitor: "GoodDay", promoZone: "East Zone", discount: "Aggressive" },
    ],
  },
  instantWins: {
    highValueSales: [
      { outlet: "Sharma Mart, East Zone", value: "₹30,000" },
      { outlet: "Desai Supermarket, North Zone", value: "₹25,000" },
    ],
    mostSoldProduct: "Chocolate Chip Cookies",
    newOutletsAcquired: { count: 20, target: 25 },
  },
  nextBestAction: {
    highPotentialOutlets: [
      {
        name: "SuperMaya Market (East Zone)",
        estimatedMonthlySales: "₹100,000",
        predictedSalesValue: "₹12,000",
      },
    ],
    lastOrderValue: "₹12,000",
  },
  competitorPulse: {
    topCompetitorProducts: ["GoodDay (70% presence)", "Oreo"],
    regionalPromotions: [
      {
        region: "East Zone",
        competitor: "GoodDay",
        promotion: "Discounts on Cookies",
      },
    ],
  },
  customerFeedback: {
    satisfactionScore: "4.2/5",
    positiveComments: ["Great taste and quality of cookies"],
    improvementSuggestions: ["Need sturdier packaging"],
    repeatCustomerRate: "65%",
    resolvedIssues: "85%",
  },
};
export default dataASM;
