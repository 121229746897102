import React from "react";
import { Grid, IconButton, Typography, useMediaQuery } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import CardWrapper from "../atoms/cardWrapper";
import ContainerWrapper from "../atoms/containerWrapper";
import DataTable from "../atoms/tableWrapper";
import dataASM from "./areaSalesManagerData_ASM";
import TypographyWrapper from "../atoms/TypographyWrapper";
import { useTheme } from "@emotion/react";
import MobileLogo from "../MobileLogo";
const TopOutletOpportunitiesASM = () => {
  const navigate = useNavigate();
  const columns = [
    { label: "Area Number", field: "outletNumber" },
    { label: "Description", field: "description" },
    { label: "Potential Revenue", field: "potentialRevenue" },
    { label: "Target Outlets", field: "targetOutlets", align: "right" },
    {
      label: "Products to Focus",
      field: "productsToFocus",
      render: (row) =>
        row.productsToFocus
          ? row.productsToFocus.join(", ")
          : "No Products Available",
    },
  ];
  const rows = dataASM.topOpportunities.map((item, index) => ({
    outletNumber: index + 1,
    description: item.description,
    potentialRevenue: item.potentialRevenue,
    targetOutlets: item.targetOutlets || "N/A",
    productsToFocus: item.productsToPush || item.productsToFocus,
  }));
  const theme = useTheme();

  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {isMediumDown && <MobileLogo />}
      <ContainerWrapper>
        <CardWrapper>
          <TypographyWrapper variant="h6">
            Growth Opportunities
          </TypographyWrapper>
          <Typography variant="body2">
            Identifying key Areas, markets, and strategies to maximize sales and
            business expansion.
          </Typography>
          <DataTable columns={columns} rows={rows} />
        </CardWrapper>
      </ContainerWrapper>
    </>
  );
};

export default TopOutletOpportunitiesASM;
