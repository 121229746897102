import React from "react";
import { Container, Typography, Card, CardContent, Grid } from "@mui/material";
import { styled, useMediaQuery } from "@mui/system";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link, useNavigate } from "react-router-dom";
import MobileLogo from "./MobileLogo";

import { useTheme } from "@emotion/react";
const TitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  cursor: "pointer",
  transition: "color 0.2s ease",
  fontSize: "1.10rem",
  "&:hover": {
    color: "red",
  },
}));

const SmallArrow = styled(ArrowForwardIosIcon)(({ theme }) => ({
  cursor: "pointer",
  transition: "transform 0.1s ease",
  paddingLeft: "4px",
  paddingTop: "4px",
  fontSize: "1rem",
}));

const ScreenSections_ASM = () => {
  const themeItems2 = [
    {
      title: "Sales Dashboard",
      description: "View today's sales performance and top products.",
      link: "/asm-today-sales",
    },
    {
      title: "Growth Opportunities",
      description: "Find outlets and areas to boost sales.",
      link: "/asm-top-outlets",
    },
    {
      title: "Sales Tips",
      description: "Follow recommended actions to grow sales.",
      link: "/asm-next-best-action",
    },
    {
      title: "Team Highlights",
      description: "Spotlight top teams and sales reps.",
      link: "/asm-hero-of-the-day",
    },
    {
      title: "Product Focus",
      description: "Push key products for better sales.",
      link: "/asm-product-push-priority",
    },
    {
      title: "Competitor Insights",
      description: "See competitor promotions and trends.",
      link: "/asm-competitor-pulse",
    },
    {
      title: "Quick Wins",
      description: "Get instant actions for sales improvement.",
      link: "/asm-instant-wins",
    },
    {
      title: "Territory Insights",
      description: "Track new outlet performance and revenue.",
      link: "/asm-outlet-radar",
    },
    {
      title: "Customer Feedback",
      description: "Check satisfaction scores and comments.",
      link: "/asm-feedback-scorecard",
    },
    {
      title: "Sales Barriers",
      description: "Address low stock and delivery issues.",
      link: "/asm-sales-obstacle-map",
    },
  ];

  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Container
      maxWidth="lg"
      sx={{
        backgroundColor: "hsl(0deg 0% 86.7%))",
        paddingLeft: { xs: "3%", md: "16px" },
        paddingRight: { xs: "2%", md: "16px" },
        paddingBottom: { xs: "1%", md: "20%" },
        paddingTop: { xs: "19%", md: "" },
        height: { xs: "100vh", md: "auto" },
        overflowY: { xs: "auto", md: "unset" },
      }}
    >
      {isMediumDown && <MobileLogo />}

      <Card
        sx={{
          padding: 3,
          boxShadow: 0,
          paddingTop: 5,
          borderRadius: { xs: 0, md: 5 },
        }}
      >
        {/* <Typography
          variant="h6"
          gutterBottom
          fontWeight="bold"
          sx={{
            paddingBottom: { xs: "2.5%", md: "1%" },
            paddingTop: { xs: "2.5%", md: "1%" },
            fontFamily: "sans-serif",
          }}
        >
          Sales Growth & Strategy Insights{" "}
        </Typography> */}
        <Grid container spacing={2}>
          {themeItems2.map((item, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Link
                to={item.link}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Card
                  sx={{
                    maxWidth: { xs: "100%", md: 500 },
                    margin: "auto",
                    boxShadow: 0,
                    borderRadius: 2,
                  }}
                  variant="outlined"
                >
                  <CardContent>
                    <TitleTypography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        fontSize: "16px",
                        color: "#6580d2",
                      }}
                    >
                      {item.title} <SmallArrow />
                    </TitleTypography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        display: {
                          xs: "block",
                          sm: "block",
                          fontSize: "13px",
                        },
                      }}
                    >
                      {item.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Card>
    </Container>
  );
};
export default ScreenSections_ASM;
