import React from "react";
import { Grid, IconButton, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dashboardData from "./salesFieldExecutiveData";
import CardWrapper from "../atoms/cardWrapper";
import ContainerWrapper from "../atoms/containerWrapper";
import TypographyWrapper from "../atoms/TypographyWrapper";
import { useTheme } from "@emotion/react";
import MobileLogo from "../MobileLogo";

const CompetitorPulse = () => {
  const theme = useTheme();

  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {isMediumDown && <MobileLogo />}
      <ContainerWrapper>
        <Grid container spacing={3}>
          {/* Competitor Pulse Header */}
          <Grid item xs={12}>
            <CardWrapper>
              <TypographyWrapper variant="h6">
                Competitor Pulse
              </TypographyWrapper>
              <TypographyWrapper variant="body2" fontWeight="normal">
                Key competitor activities and their impact.
              </TypographyWrapper>
            </CardWrapper>
          </Grid>

          {/* Competitor Promo Observed */}
          <Grid item xs={12} md={6}>
            <CardWrapper>
              <TypographyWrapper variant="subtitle1">
                Competitor Promo Observed
              </TypographyWrapper>
              <TypographyWrapper variant="body1" fontWeight="normal">
                {dashboardData.competitorPulse.competitorPromoObserved}
              </TypographyWrapper>
            </CardWrapper>
          </Grid>

          {/* Top Competitor Products Nearby */}
          <Grid item xs={12} md={6}>
            <CardWrapper>
              <TypographyWrapper variant="subtitle1">
                Top Competitor Products Nearby
              </TypographyWrapper>
              <TypographyWrapper variant="body1" fontWeight="normal">
                {dashboardData.competitorPulse.topCompetitorProductsNearby.join(
                  ", "
                )}
              </TypographyWrapper>
            </CardWrapper>
          </Grid>

          {/* New Competitor Stock at Outlets */}
          <Grid item xs={12} md={6}>
            <CardWrapper>
              <TypographyWrapper variant="subtitle1">
                New Competitor Stock at Outlets
              </TypographyWrapper>
              <TypographyWrapper variant="body1" fontWeight="normal">
                {
                  dashboardData.competitorPulse.newCompetitorStockAtOutlets
                    .product
                }{" "}
                at{" "}
                {
                  dashboardData.competitorPulse.newCompetitorStockAtOutlets
                    .location
                }
              </TypographyWrapper>
            </CardWrapper>
          </Grid>

          {/* Estimated Impact */}
          <Grid item xs={12} md={6}>
            <CardWrapper>
              <TypographyWrapper variant="subtitle1">
                Estimated Impact
              </TypographyWrapper>
              <TypographyWrapper variant="body1" fontWeight="normal">
                {dashboardData.competitorPulse.estimatedImpact}
              </TypographyWrapper>
            </CardWrapper>
          </Grid>
        </Grid>
      </ContainerWrapper>
    </>
  );
};

export default CompetitorPulse;
