import React from "react";
import { Grid, IconButton, useMediaQuery } from "@mui/material";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import dashboardData from "./salesFieldExecutiveData";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ContainerWrapper from "../atoms/containerWrapper";
import CardWrapper from "../atoms/cardWrapper";
import ProgressBar from "../atoms/progressBar";
import DataTable from "../atoms/tableWrapper";
import MetricCard from "../atoms/metricCard";
import TypographyWrapper from "../atoms/TypographyWrapper";
import { useTheme } from "@emotion/react";
import MobileLogo from "../MobileLogo";

const SalesOverviewPage = () => {
  const {
    todaysSales,
    topProductOfDay,
    totalRevenueWeekly,
    leaderboard,
    weeklyTargetCompletion,
    averageOrderValue,
    conversionRate,
    returnRateToday,
  } = dashboardData.dailySalesPulse;

  const chartData = [
    { name: "Achieved", value: parseFloat(todaysSales.achieved) },
    { name: "Remaining", value: 100 - parseFloat(todaysSales.achieved) },
  ];
  const COLORS = ["#4caf50", "#f44336"];

  // Define columns and rows for DataTable
  const columns = [
    { label: "Metric", field: "metric" },
    { label: "Value", field: "value", align: "right" },
  ];

  const rows = [
    { metric: "Today’s Sales", value: todaysSales.value },
    { metric: "Sales Target", value: todaysSales.target },
    { metric: "Percentage Achieved", value: todaysSales.achieved },
  ];

  const theme = useTheme();

  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <ContainerWrapper>
      {isMediumDown && <MobileLogo />}

      <Grid container spacing={3}>
        {/* Sales Details Table */}
        <Grid item xs={12} md={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">
              Today’s Sales Overview
            </TypographyWrapper>
            <TypographyWrapper variant="body2" fontWeight="normal">
              An overview of the current sales performance, including{" "}
              <span style={{ backgroundColor: "#ffeb3b", color: "black" }}>
                daily revenue
              </span>
              ,{" "}
              <span style={{ backgroundColor: "#ffeb3b", color: "black" }}>
                target achievement
              </span>
              , and additional metrics to track progress.
            </TypographyWrapper>

            <DataTable columns={columns} rows={rows} />
          </CardWrapper>
        </Grid>

        {/* Target Achievement Chart */}
        <Grid item xs={12} md={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">
              Target Achievement
            </TypographyWrapper>
            <TypographyWrapper variant="body2" fontWeight="normal">
              This chart shows the progress toward today’s sales target.
            </TypographyWrapper>
            <ResponsiveContainer width="100%" height={250}>
              <PieChart>
                <Pie data={chartData} dataKey="value" outerRadius={80} label>
                  {chartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </CardWrapper>
        </Grid>

        {/* Top Product of the Day */}
        <Grid item xs={12} md={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">
              Top Product of the Day
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {topProductOfDay.name} - {topProductOfDay.unitsSold} Units Sold
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Total Revenue */}
        <Grid item xs={12} sm={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">
              Total Revenue (Weekly)
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {totalRevenueWeekly}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Leaderboard Rank */}
        <Grid item xs={12} sm={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">Leaderboard Rank</TypographyWrapper>
            <TypographyWrapper variant="h6">
              #{leaderboard.rank} of {leaderboard.totalRanks}
            </TypographyWrapper>
            <TypographyWrapper
              variant="body2"
              fontWeight="normal"
              color="textSecondary"
            >
              Next milestone: {leaderboard.nextMilestone}
            </TypographyWrapper>
            <ProgressBar value={weeklyTargetCompletion} />
          </CardWrapper>
        </Grid>

        {/* Additional Metrics */}
        <MetricCard
          title="Weekly Target Completion"
          value={`${weeklyTargetCompletion}`}
          progressValue={parseFloat(weeklyTargetCompletion)}
        />

        <MetricCard
          title="Conversion Rate"
          value={conversionRate}
          progressValue={35}
        />
        <MetricCard
          title="Return Rate Today"
          value={returnRateToday}
          progressValue={2}
        />
      </Grid>
    </ContainerWrapper>
  );
};

export default SalesOverviewPage;
