import React from "react";
import { Grid, IconButton,useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ContainerWrapper from "../atoms/containerWrapper";
import CardWrapper from "../atoms/cardWrapper";
import DataTable from "../atoms/tableWrapper";
import MetricCard from "../atoms/metricCard";
import data from "./areaSalesManagerData_ASM";
import TypographyWrapper from "../atoms/TypographyWrapper";
import { useTheme } from "@emotion/react";
import MobileLogo from "../MobileLogo";
const TodaySalesASM = () => {
  const navigate = useNavigate();
  const {
    todaysSales,
    topProduct,
    totalRevenueWeekly,
    topPerformingTeam,
    weeklyTargetCompletion,
    conversionRate,
  } = data.dailySalesPulse;
  const columns = [
    { label: "Metric", field: "metric" },
    { label: "Value", field: "value", align: "right" },
  ];

  const rows = [
    { metric: "Today’s Sales", value: todaysSales.value },
    { metric: "Sales Target", value: todaysSales.target },
    { metric: "Percentage Achieved", value: todaysSales.achieved },
  ];

  const theme = useTheme();

  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {isMediumDown && <MobileLogo />}
      <ContainerWrapper>

      <Grid container spacing={3}>
        {/* Sales Details Table */}
        <Grid item xs={12} md={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">
              Today’s Sales Overview
            </TypographyWrapper>
            <TypographyWrapper variant="body2" fontWeight="normal">
              An overview of the current sales performance, including daily
              revenue,target achievement, and additional metrics to track
              progress.
            </TypographyWrapper>
            <DataTable columns={columns} rows={rows} />
          </CardWrapper>
        </Grid>
        {/* Top Product of the Day */}
        <Grid item xs={12} md={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">
              Top Product of the Day
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {topProduct.name} - {topProduct.unitsSold} Units Sold
            </TypographyWrapper>
          </CardWrapper>
        </Grid>
        {/* Total Revenue */}
        <Grid item xs={12} sm={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">
              Total Revenue (Weekly)
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {totalRevenueWeekly}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>
        {/* Additional Metrics */}
        <MetricCard
          title="Weekly Target Completion"
          value={`${weeklyTargetCompletion}`}
          progressValue={parseFloat(weeklyTargetCompletion)}
        />
        <MetricCard
          title="Conversion Rate"
          value={`${conversionRate}`}
          progressValue={parseFloat(conversionRate)}
        />
      </Grid>
    </ContainerWrapper>
    </>
  );
};

export default TodaySalesASM;
