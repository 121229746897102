import React from "react";
import { Grid, IconButton,useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CardWrapper from "../atoms/cardWrapper";
import ContainerWrapper from "../atoms/containerWrapper";
import { useTheme } from "@emotion/react";
import MobileLogo from "../MobileLogo";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import TypographyWrapper from "../atoms/TypographyWrapper";
const TeamPerformance = () => {
  const navigate = useNavigate();
  const teamPerformance = {
    topTeam: "North Zone",
    totalSales: "₹6,000,000",
    challengeStatus: { completion: "85%", topRep: "Arjun (300 Units Sold)" },
    achievementBadges: ["Best Sales Team", "Fastest Growing Territory"],
  };

  // Data for Bar Chart
  const chartData = [
    { name: "North Zone", sales: 6000000 },
    { name: "West Zone", sales: 3000000 },
    { name: "South Zone", sales: 1000000 },
  ];

  const theme = useTheme();

  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {isMediumDown && <MobileLogo />}
      <ContainerWrapper>

      <Grid container spacing={3}>
        {/* New Territory Performance Header */}
        {/* Team Performance Header */}
        <Grid item xs={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">Team Performance</TypographyWrapper>
            <TypographyWrapper variant="body2" fontWeight="normal">
              Overview of team achievements and sales performance
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Top Team */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">Top Team</TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {teamPerformance.topTeam}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Total Sales */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Total Sales
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {teamPerformance.totalSales}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Challenge Status */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Challenge Status
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              Completion: {teamPerformance.challengeStatus.completion}
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              Top Rep: {teamPerformance.challengeStatus.topRep}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Achievement Badges */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Achievement Badges
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {teamPerformance.achievementBadges.join(", ")}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Sales Performance Chart */}
        <Grid item xs={12}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Sales Performance by Zone
            </TypographyWrapper>
            <ResponsiveContainer width="100%" height={350}>
              <BarChart
                data={chartData}
                margin={{ top: 20, right: 30, left: 20, bottom: 50 }}
              >
                <XAxis
                  dataKey="name"
                  tick={{ angle: -30, textAnchor: "end" }}
                  interval={0}
                />
                <YAxis
                  allowDecimals={false}
                  tickFormatter={(value) => `₹${(value / 1000000).toFixed(1)}M`}
                />
                <Tooltip formatter={(value) => `₹${value.toLocaleString()}`} />
                <Bar dataKey="sales" fill="#8884d8" barSize={30} />
              </BarChart>
            </ResponsiveContainer>
          </CardWrapper>
        </Grid>
      </Grid>
    </ContainerWrapper>
    </>
  );
};

export default TeamPerformance;
