import { useTheme } from "@emotion/react";
import { Box, Chip, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MessageInput from "./input/messageInput";
import PulseLoader from "react-spinners/PulseLoader";
import UnibicLogo from "../assets/unibic_logo.png";

const Home = ({
  categories,
  handleItemClick,
  // isLoading,
  // userLang,
  // setUserLang,
  // setIsInputFocused,
  isItemLoading,
}) => {
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  const isRotated = useMediaQuery("(orientation: landscape)");

  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: isMediumDown ? "flex-start" : "space-around",
        alignItems: "center",
        flexDirection: "column",
        height: isMediumDown ? "" : "100vh",
        flexGrow: 1,
        gap: "8%",
        padding: "3%",
        pointerEvents: isItemLoading ? "none" : "auto",
      }}
    >
      {isItemLoading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <PulseLoader color="white" size={15} />
        </Box>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
          width: "100%",
        }}
      >
        {isMediumDown && (
          <div
            style={{
              display: "flex",
              width: "95%",
              justifyContent: "space-between",
              position: "fixed",
              top: 0,
              left: 0,
              padding: isRotated ? "1%" : "3% 3% 7%",
              backgroundColor: "#f0f2f8",
            }}
          >
            <img
              fetchpriority="high"
              width={"100"}
              height={"44"}
              src={UnibicLogo}
              className="attachment-full size-full wp-image-2014"
              alt=""
              sizes="(max-width: 500px) 100vw, 500px"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  fontFamily: "Roboto",
                  color: "#555555",
                }}
              >
                AI Agent
              </div>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "Roboto",
                  color: "#555555",
                }}
              >
                by channelco.ai
              </div>
            </div>
          </div>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: isMediumDown && !isRotated ? "column" : "row",
            padding: isMediumDown ? (isRotated ? "1%" : "6%") : "20% 20%",
            marginTop: isMediumDown && isRotated ? "5%" : "8%",
            width:"100%"
          }}
        >
          {categories.map((category) => (
            <Box
              key={category.title}
              sx={{
                width: "100%",
                mb: isMediumDown ? "10px" : 3,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <Typography
                sx={{
                  mb: isMediumDown ? "8px" : 1,
                  fontSize: isMediumDown ? "14px" : "16px",
                  fontWeight: "bolder",
                  textAlign: "center",
                  color: "hsl(207, 12%, 43%)",
                }}
              >
                {category.title}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  gap: isMediumDown ? "8px" : 1,
                  justifyContent: "flex-start",
                }}
              >
                {category.items.map((item) => (
                  <Chip
                    key={item.path}
                    label={item.label}
                    onClick={() => handleItemClick(item, navigate)}
                    sx={{
                      backgroundColor: "inherit",
                      border: "1px solid hsl(0deg 0% 59% / 50%)",
                      color: "hsl(207, 12%, 43%)",
                      borderRadius: "6px",
                      padding: "8px",
                      width: "fit-content",
                      cursor: "pointer",
                      fontSize: "13px",
                      "&:hover": {
                        backgroundColor: "hsl(0, 0%, 100%)",
                        color: "hsl(243, 96%, 67%)",
                      },
                    }}
                  />
                ))}
              </Box>
            </Box>
          ))}
        </div>
      </div>
    </Box>
  );
};

export default Home;
