import React from "react";
import { Typography } from "@mui/material";

const TypographyWrapper = ({
  children,
  fontWeight = "bold",
  paddingTop = { xs: "1%", md: "3.5%", sm: "1%", lg: "1%" },
  paddingBottom = { xs: "1%", md: "3.5%", sm: "1%", lg: "1%" },
  paddingLeft = { xs: "4%", md: "0%" },
  color = "inherit",
  sx = {},
  ...props // Capture additional props
}) => {
  return (
    <Typography
      fontWeight={fontWeight}
      color={color}
      sx={{
        paddingTop,
        paddingBottom,
        paddingLeft,
        ...sx, // Merge user-provided sx styles
      }}
      {...props} // Spread additional props into Typography
    >
      {children}
    </Typography>
  );
};

export default TypographyWrapper;
