import React from "react";
import { Grid, IconButton, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CardWrapper from "../atoms/cardWrapper";
import ContainerWrapper from "../atoms/containerWrapper";
import TypographyWrapper from "../atoms/TypographyWrapper";
import { useTheme } from "@emotion/react";
import MobileLogo from "../MobileLogo";
const NewTerritoryASM = () => {
  const navigate = useNavigate();
  const newTerritoryPerformance = {
    newOutletsAdded: { count: 50, target: 60 },
    topArea: "West Zone",
    averageRevenuePerNewOutlet: "₹20,000",
    competitors: ["GoodDay", "Sunfeast"],
    scheduledVisits: 15,
  };

  const theme = useTheme();

  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {isMediumDown && <MobileLogo />}
      <ContainerWrapper>

      <Grid container spacing={3}>
        {/* New Territory Performance Header */}
        <Grid item xs={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">
              New Territory Performance
            </TypographyWrapper>
            <TypographyWrapper variant="body2" fontWeight="normal">
              Key metrics for evaluating new market expansion
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* New Outlets Added */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              New Outlets Added
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {newTerritoryPerformance.newOutletsAdded.count} /{" "}
              {newTerritoryPerformance.newOutletsAdded.target}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Top Area */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">Top Area</TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {newTerritoryPerformance.topArea}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Average Revenue Per New Outlet */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Avg. Revenue Per New Outlet
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {newTerritoryPerformance.averageRevenuePerNewOutlet}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Competitors */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Competitors
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {newTerritoryPerformance.competitors.join(", ")}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Scheduled Visits */}
        <Grid item xs={12}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Scheduled Visits
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {newTerritoryPerformance.scheduledVisits}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>
      </Grid>
    </ContainerWrapper>
    </>
  );
};

export default NewTerritoryASM;
