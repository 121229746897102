import React from "react";
import { Grid, IconButton, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dashboardData from "./salesFieldExecutiveData";
import CardWrapper from "../atoms/cardWrapper";
import ContainerWrapper from "../atoms/containerWrapper";
import TypographyWrapper from "../atoms/TypographyWrapper";
import { useTheme } from "@emotion/react";
import MobileLogo from "../MobileLogo";

const CustomerFeedbackScorecard = () => {
  const theme = useTheme();

  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <ContainerWrapper>
      {isMediumDown && <MobileLogo />}
      <Grid container spacing={3}>
        {/* Customer Feedback Scorecard Header */}
        <Grid item xs={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">
              Customer Feedback Scorecard
            </TypographyWrapper>
            <TypographyWrapper variant="body2" fontWeight="normal">
              A summary of customer feedback and satisfaction metrics.
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Satisfaction Score */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Satisfaction Score
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.customerFeedbackScorecard.satisfactionScore}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Top Positive Feedback */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Top Positive Feedback
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.customerFeedbackScorecard.topPositiveFeedback}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Top Negative Feedback */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Top Negative Feedback
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.customerFeedbackScorecard.topNegativeFeedback}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Repeat Customer Rate */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Repeat Customer Rate
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.customerFeedbackScorecard.repeatCustomerRate}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Resolved Issues */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Resolved Issues
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.customerFeedbackScorecard.resolvedIssues.count} out
              of {dashboardData.customerFeedbackScorecard.resolvedIssues.total}{" "}
              issues resolved
            </TypographyWrapper>
          </CardWrapper>
        </Grid>
      </Grid>
    </ContainerWrapper>
  );
};

export default CustomerFeedbackScorecard;
