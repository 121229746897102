import React from "react";
import { Grid, IconButton,useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ContainerWrapper from "../atoms/containerWrapper";
import CardWrapper from "../atoms/cardWrapper";
import data from "./areaSalesManagerData_ASM";
import TypographyWrapper from "../atoms/TypographyWrapper";
import { useTheme } from "@emotion/react";
import MobileLogo from "../MobileLogo";
const SalesObstacleMapASM = () => {
  const navigate = useNavigate();
  const {
    lowStockOutlets,
    returnIssues,
    delayedDeliveries,
    competitorPromotions,
  } = data.salesObstacleMap;

  const theme = useTheme();

  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {isMediumDown && <MobileLogo />}
      <ContainerWrapper>
      <Grid container spacing={3}>
        {/* Sales Obstacle Map Header */}
        <Grid item xs={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">
              Sales Obstacle Map
            </TypographyWrapper>
            <TypographyWrapper variant="body2" fontWeight="normal">
            Identifying and addressing sales challenges
                        </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Low Stock Outlets */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Low Stock Outlets
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {lowStockOutlets} outlets
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Return Issues */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Top Return Issues
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {returnIssues.join(", ")}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Delayed Deliveries */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Delayed Deliveries
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {delayedDeliveries.North.count} delays (Avg. delay:{" "}
              {delayedDeliveries.North.averageDelay})
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Competitor Promotions */}
        <Grid item xs={12}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Major Competitor Activity
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {competitorPromotions
                .map(
                  (promo, index) =>
                    `${promo.competitor} in ${promo.promoZone} zone with ${promo.discount} discount`
                )
                .join(", ")}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>
      </Grid>
    </ContainerWrapper>
    </>
  );
};

export default SalesObstacleMapASM;
