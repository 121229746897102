import { useTheme } from "@emotion/react";
import { Box, Chip, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MessageInput from "./input/messageInput";
import PulseLoader from "react-spinners/PulseLoader";

const Home1 = ({
  categories,
  handleItemClick,
  isLoading,
  userLang,
  setUserLang,
  setIsInputFocused,
  isItemLoading,
}) => {
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const handleSendInHome = (message) => {
    navigate("/chat", { state: { initialMessage: message } });
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: isMediumDown ? "space-between" : "space-around",
        alignItems: "center",
        flexDirection: "column",
        height: isMediumDown ? "" : "100vh",
        flexGrow: 1,
        gap: "8%",
        padding: "3%",
        pointerEvents: isItemLoading ? "none" : "auto",
      }}
    >
      {isItemLoading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <PulseLoader color="white" size={15} />
        </Box>
      )}
      {isMediumDown && (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <img
            fetchpriority="high"
            width={isMediumDown ? "100" : "250"}
            height={isMediumDown ? "44" : "111"}
            src="https://www.unibicfoods.com/wp-content/uploads/2022/12/logo_Red-1.png"
            class="attachment-full size-full wp-image-2014"
            alt=""
            srcset="https://www.unibicfoods.com/wp-content/uploads/2022/12/logo_Red-1.png 500w, https://www.unibicfoods.com/wp-content/uploads/2022/12/logo_Red-1-300x133.png 300w"
            sizes="(max-width: 500px) 100vw, 500px"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: "16px",
                fontWeight: "500",
                fontFamily: "Roboto",
                color: "#555555",
              }}
            >
              AI Agent
            </div>
            <div
              style={{
                fontSize: "16px",
                fontWeight: "400",
                fontFamily: "Roboto",
                color: "#555555",
              }}
            >
              by channelco.ai
            </div>
          </div>
        </div>
      )}

      <div
        style={{
          display: "flex",
          gap: "4%",
          flexDirection: isMediumDown ? "column" : "row",
          padding: isMediumDown ? "6%" : "20% 20%",
        }}
      >
        {categories.map((category) => (
          <Box
            key={category.title}
            sx={{
              width: "100%",
              mb: isMediumDown ? "10px" : 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <Typography
              sx={{
                mb: isMediumDown ? "8px" : 1,
                fontSize: isMediumDown ? "14px" : "16px",
                fontWeight: "bolder",
                textAlign: "center",
                color: "hsl(207, 12%, 43%)",
              }}
            >
              {category.title}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                gap: isMediumDown ? "5px" : 1,
                justifyContent: "center",
              }}
            >
              {category.items.map((item) => (
                <Chip
                  key={item.path}
                  label={item.shortLabel}
                  onClick={() => handleItemClick(item, navigate)}
                  sx={{
                    backgroundColor: "inherit",
                    border: "1px solid hsl(0deg 0% 59% / 50%)",
                    color: "hsl(207, 12%, 43%)",
                    borderRadius: "16px",
                    padding: "8px",
                    width: "fit-content",
                    cursor: "pointer",
                    fontSize: "13px",
                    "&:hover": {
                      backgroundColor: "hsl(0, 0%, 100%)",
                      color: "hsl(243, 96%, 67%)",
                    },
                  }}
                />
              ))}
            </Box>
          </Box>
        ))}
      </div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "sticky",
          bottom: "0",
          padding: isMediumDown ? "0" : 2,
          width: "100%",
          
        }}
      >
        <MessageInput
          handleSend={handleSendInHome}
          userLang={userLang}
          setUserLang={setUserLang}
          isLoading={isLoading}
          setIsInputFocused={setIsInputFocused}
        />
      </Box>
    </Box>
  );
};

export default Home1;
