import React from "react";
import { Grid, IconButton,useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CardWrapper from "../atoms/cardWrapper";
import ContainerWrapper from "../atoms/containerWrapper";
import TypographyWrapper from "../atoms/TypographyWrapper";
import { useTheme } from "@emotion/react";
import MobileLogo from "../MobileLogo";

const customerFeedback = {
  satisfactionScore: "4.2/5",
  positiveComments: ["Great taste and quality of cookies"],
  improvementSuggestions: ["Need sturdier packaging"],
  repeatCustomerRate: "65%",
  resolvedIssues: "85%",
};
const CustomerFeedbackScorecardASM = () => {
  const theme = useTheme();

  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {isMediumDown && <MobileLogo />}
      <ContainerWrapper>

      <Grid container spacing={3} justifyContent="center" alignItems="center">
        {/* Customer Feedback Scorecard Header */}
        <Grid item xs={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6"> Customer Feedback Scorecard
            </TypographyWrapper>
            <TypographyWrapper variant="body2" fontWeight="normal">
            Insights to enhance customer satisfaction and drive sales growth    
                    </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Satisfaction Score */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Satisfaction Score
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {customerFeedback.satisfactionScore}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Positive Comments */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Top Positive Comments
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {customerFeedback.positiveComments.join(", ")}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Improvement Suggestions */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Improvement Suggestions
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {customerFeedback.improvementSuggestions.join(", ")}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Repeat Customer Rate */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Repeat Customer Rate
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {customerFeedback.repeatCustomerRate}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Resolved Issues */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Resolved Issues
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {customerFeedback.resolvedIssues} of issues resolved
            </TypographyWrapper>
          </CardWrapper>
        </Grid>
      </Grid>
    </ContainerWrapper>
    </>
  );
};

export default CustomerFeedbackScorecardASM;
