import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Container,
  BottomNavigation,
  BottomNavigationAction,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import AssistantIcon from "@mui/icons-material/Assistant";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import UnibicLogo from "../../assets/unibic_logo.png";

function UnibicNav({ isInputFocused, isAuthenticated, handleLogout }) {
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails = JSON.parse(sessionStorage.getItem("userDetails") || "{}");
  const role = userDetails?.role;

  const shouldShowTitleImage = location.pathname !== "/home" && isAuthenticated;
  const getSelectedValue = () => {
    switch (location.pathname) {
      case "/home":
        return 0;
      case "/chat":
        return 1;
      case "/screens" || "/asm-dashboard":
        return 2;
      case isAuthenticated ? "/Logout" : "/login":
        return 4;
      default:
        return -1;
    }
  };
  const [value, setValue] = useState(getSelectedValue());
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const storedUser = sessionStorage.getItem("userDetails");
  const user = JSON.parse(storedUser);

  useEffect(() => {
    setValue(getSelectedValue());
  }, [location]);

  const handleLogoutConfirm = () => {
    if (!user) {
      setOpenLogoutDialog(false);
      navigate("/login");
      return;
    }
    setOpenLogoutDialog(false);
    handleLogout(user?.username);
    navigate("/login");
  };

  const handleLogoutClick = () => {
    setOpenLogoutDialog(true);
  };

  const handleDialogClose = () => {
    setOpenLogoutDialog(false);
    navigate("/home");
  };

  return (
    <>
      {isMediumDown ? (
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            if (newValue === 0) navigate("/home");
            if (newValue === 1) navigate("/chat");
            if (newValue === 2)
              navigate(
                role === "reporting_manager" ? "/asm-dashboard" : "/screens"
              );
            if (newValue === 3)
              isAuthenticated ? handleLogoutClick() : navigate("/login");
          }}
          sx={{
            display: isInputFocused ? "none" : "",
            position: isMediumDown ? "sticky" : "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            background: "#eaeef5",
            zIndex: theme.zIndex.drawer + 1,
            "& .Mui-selected": {
              color: "#d52c2c",
            },
            "& .MuiBottomNavigationAction-root.Mui-selected": {
              "& .MuiBottomNavigationAction-label": {
                color: "#d52c2c",
              },
              "& .MuiSvgIcon-root": {
                color: "#d52c2c",
              },
            },
          }}
        >
          <BottomNavigationAction label="Home" icon={<HomeIcon />} />
          <BottomNavigationAction label="Chat" icon={<AssistantIcon />} />
          <BottomNavigationAction
            label="Screens"
            icon={<TipsAndUpdatesIcon />}
          />
          <BottomNavigationAction
            label={isAuthenticated ? "Logout" : "Login"}
            icon={
              isAuthenticated ? (
                <div
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: "50%",
                    backgroundColor: "#d52c2c",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  {user?.name[0]?.toUpperCase()}
                </div>
              ) : (
                <AccountCircleIcon />
              )
            }
          />
        </BottomNavigation>
      ) : (
        <AppBar
          position="fixed"
          sx={{
            height: "10%",
            boxShadow: "none",
            zIndex: theme.zIndex.drawer + 1,
            background: "inherit",
          }}
        >
          <Toolbar
            disableGutters
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
              height: "100%",
              gap: "2%",
              maxWidth: "100%",
              padding: "0 2%",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "3%",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Link
                to="/home"
                style={{
                  textDecoration: "none",
                  color: "hsl(207, 12%, 43%)",
                  fontSize: "16px",
                  fontWeight: "bolder",
                }}
              >
                HOME
              </Link>
              <Link
                to="/chat"
                style={{
                  textDecoration: "none",
                  color: "hsl(207, 12%, 43%)",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                CHAT
              </Link>
              <Link
                to={
                  role === "reporting_manager" ? "/asm-dashboard" : "/screens"
                }
                style={{
                  textDecoration: "none",
                  color: "hsl(207, 12%, 43%)",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                SCREENS
              </Link>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  marginLeft: "30%",
                  alignItems: "center",
                }}
              >
                <img
                  fetchpriority="high"
                  width={"140"}
                  height={"64"}
                  src={UnibicLogo}
                  className="attachment-full size-full wp-image-2014"
                  alt=""
                  sizes="(max-width: 500px) 100vw, 500px"
                />

                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    fontFamily: "Roboto",
                    color: "#555555",
                  }}
                >
                  AI Agent by channelco.ai
                </div>
              </div>
            </div>
            <Button
              onClick={() => {
                isAuthenticated ? handleLogoutClick() : navigate("/login");
              }}
              sx={{
                color: "hsl(207, 12%, 43%)",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              {isAuthenticated ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: 32,
                      height: 32,
                      borderRadius: "50%",
                      backgroundColor: "#d52c2c",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    {user?.name[0]?.toUpperCase()}
                  </div>
                  Logout
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <AccountCircleIcon />
                  LOGIN
                </div>
              )}
            </Button>
          </Toolbar>
        </AppBar>
      )}
      <Dialog
        open={openLogoutDialog}
        onClose={handleDialogClose}
        aria-labelledby="logout-dialog-title"
        aria-describedby="logout-dialog-description"
      >
        <DialogTitle id="logout-dialog-title">Logout Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="logout-dialog-description">
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ backgroundColor: "", color: "red" }}
            onClick={handleDialogClose}
          >
            Cancel
          </Button>
          <Button
            sx={{ backgroundColor: "", color: "red" }}
            onClick={handleLogoutConfirm}
          >
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UnibicNav;
