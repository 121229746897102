import React from "react";
import { Grid, IconButton, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dashboardData from "./salesFieldExecutiveData";
import ContainerWrapper from "../atoms/containerWrapper";
import CardWrapper from "../atoms/cardWrapper";
import TypographyWrapper from "../atoms/TypographyWrapper";
import MobileLogo from "../MobileLogo";
import { useTheme } from "@emotion/react";

const NextBestAction = () => {
  const theme = useTheme();

  const isMediumDown = useMediaQuery(theme.breakpoints.down("md")); 
  return (
    <ContainerWrapper>
      {isMediumDown && <MobileLogo />}


      <Grid container spacing={3}>
        {/* Next Best Action Header */}
        <Grid item xs={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">
              Next Best Action
            </TypographyWrapper>
            <TypographyWrapper variant="body2" fontWeight="normal">
              Here’s the suggested action to maximize sales.
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Suggested Outlet */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">Suggested Outlet</TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.nextBestAction.suggestedOutlet.name}{" "}
              ({dashboardData.nextBestAction.suggestedOutlet.distance} away)
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Predicted Sales Value */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">Predicted Sales Value</TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.nextBestAction.predictedSalesValue}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Last Purchase Date */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">Last Purchase Date</TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.nextBestAction.lastPurchaseDate}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Order History */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">Order History</TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.nextBestAction.orderHistory.count} orders with an average value of{" "}
              {dashboardData.nextBestAction.orderHistory.averageValue}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>
      </Grid>
    </ContainerWrapper>
  );
};

export default NextBestAction;
